import React from 'react'
import { Helmet } from 'react-helmet'
import styles from './404.module.css'

const NotFoundPage = () => (
  <div className={styles.page}>
    <Helmet>
      <title>404 Not found</title>
      <link rel='preload' href='https://fonts.googleapis.com/css?family=Rochester' as='style' />
      <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Rochester' />
    </Helmet>

    <div className={styles.section}>
      <div className={styles.background}>
        <div className={styles.moon}>
          <div className={styles.left}>4</div>
          <div className={styles.right}>4</div>
        </div>
        <div className={styles.owl}>
          <div className={styles.wing1} />
          <div className={styles.wing2} />
          <div className={styles.wing3} />
          <div className={styles.wing4} />
          <div className={styles.wing5} />
          <div className={styles.wing6} />
          <div className={styles.wing7} />
          <div className={styles.wing8} />
          <div className={styles.wing9} />
          <div className={styles.wing10} />
          <div className={styles.wing11} />
          <div className={styles.wing12} />
          <div className={styles.owlhead}>
            <div className={styles.ears} />
          </div>
          <div className={styles.owlbody}>
            <div className={styles.owleyes}>
              <div className={styles.owleye}>
                <div className={`${styles.owleye} ${styles.owleyeInner}`} />
                <div className={`${styles.owleye} ${styles.owleyeInner} ${styles.owleyeInner2}`} />
                <div className={`${styles.eyelid} ${styles.eyelidTop}`} /> />
                <div className={`${styles.eyelid} ${styles.eyelidBottom}`} /> />
              </div>
              <div className={styles.owleye}>
                <div className={`${styles.owleye} ${styles.owleyeInner}`} />
                <div className={`${styles.owleye} ${styles.owleyeInner} ${styles.owleyeInner2}`} />
                <div className={`${styles.eyelid} ${styles.eyelidTop}`} /> />
                <div className={`${styles.eyelid} ${styles.eyelidBottom}`} /> />
              </div>
              <div className={styles.nose} />
            </div>
            <div className={styles.feet}>
              <div className={styles.foot1} />
              <div className={styles.foot2} />
            </div>
          </div>
          <div className={styles.branch} />
        </div>
      </div>
    </div>
    <div className={styles.section}>
      <div className={styles.message}>
        <h2>You're lost in the dark!</h2>
        <p>The page you're looking for does not exist</p>
        <br />
        <div className={styles.btndiv}>
          {/*
            We don't use Gatsby router here because of jquery plugins on home page,
            which must be executed every time the page is displayed AND on a fresh state
            (otherwise it messes up the mobile menu)
          */}
          <a href='/'>
            <button className='item-btn btn-fill'>GOTO HOME</button>
          </a>
        </div>
      </div>
    </div>
    <div className={styles.stars1} />
    <div className={styles.stars2} />
    <div className={styles.stars3} />
    <div className={styles.sstar} />
  </div>
)

export default NotFoundPage
